import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Link} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu-hasdropdown"><a href="#home">Home</a></li> 
                    <li className=" "><a href="#about">About Us</a></li> 
                    <li className="menu-hasdropdown"><a href="#services">services</a></li>
                    <li className="menu-hasdropdown"><a href="mailto:info@aerodynamicdata.com" target="_new">Contact</a></li>  
                </ul> 
            </nav>
    
            )
    }
}