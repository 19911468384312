import React, { Component } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const images = [
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg'
];

export class Home extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      const { photoIndex, isOpen } = this.state;
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        
        return (       
          <div className="site-main" id="home" >   
            <Header/>            
            {/* home banner */}
            <CarouselPage/>{/* home banner end*/}            
            {/* service-section */}     
            <section className="ttm-row service-section position-relative z-1 clearfix">
              <div className="container-xl">
                <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
                  <div className="col-md-4">
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-system" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>Quantum Control</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>The Undisputed Leader in ERP Solutions for Aviation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                          <i className="flaticon flaticon-clock" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>24/7 Support</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p><a href="tel:16194687596" googl="true">+1 619 468 7596</a></p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-developer" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>Technical Solutions</h5>
                        </div>
                        <div className="featured-desc">{/*  featured-desc */}
                          <p>MRO, Manufacturing, Distribution and Aircraft Services</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                </div>
              </div>
            </section>
            {/* service-section end */}
            {/* aboutus-section */}
            <section className="ttm-row aboutus-section clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-6">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5 id="about">About us</h5>
                        <h2 className="title">We have over 15 years of Quantum MRO<span> experience</span></h2>
                      </div>
                      <div className="title-desc">
                        <p>Component Control's core product, Quantum Control, is an ERP business software solution widely used by medium size aircraft part sellers, aviation repair, and manufacturing businesses.</p>
                        <p>Component Control's core product, Quantum Control, is an ERP business software solution widely used by medium size aircraft part sellers, aviation repair, and manufacturing businesses.</p>
                        <p>Quantum's functionality covers: MRO, Aircraft Services, Hangar Management, Manufacturing, Contact Management, Distribution & Rotable Management, Accounting & Financials. </p>
                      </div>
                    </div>{/* section title end */}
                    {/* row */}
                    <br/>
                    <h4>Our Services</h4>
                    <div className="row no-gutters mt-4"> 
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Disaster Recovery </span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Event Manager</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Implementation Services</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Quantum Dashboards</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Reporting Infrastructure</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Business Intelligence Analytics</span></li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Installation Services</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Performance Evaluation</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Process Flow Management</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Software Integrator</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Training Services</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Upgrade Support</span></li>
                        </ul>
                      </div>
                    </div>{/* row END*/}
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="fs-16 ttm-textcolor-darkgrey">
                      <span>Contact us <a className="fw-500 ttm-textcolor-darkgrey" href="tel:16194687596"><u>any time</u></a></span>
                      <strong className="fs-18">: <a href="tel:16194687596" googl="true">+1 619 468 7596</a></strong>
                    </div>
                  </div>
                  <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper"><br /><br />
                        <img className="img-fluid" src="/images/experience.jpg" title="single-img-one" alt="single-img-one" />
                      </div>{/* ttm_single_image-wrapper end */}
                      {/*featured-icon-box*/}
                      
                      {/* featured-icon-box end*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* aboutus-section end */}
            {/* history-section */}
            <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {/* section title */}
                    <div className="section-title with-desc text-center clearfix">
                      <div className="title-header">
                        <h5>Our Services</h5>
                        <h2 className="title" id="services">Aviation <br/><span>Services Technologies</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider}>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Disaster Recovery</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        Remaining operational after a disaster is critical to your business. It's in the good-times that you must implement the correct combination of tools and procedures. <strong>We're your friends in disaster recovery planning and if needed, disaster recovery.</strong>
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-computer" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Event Manager</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        We’re experts in advanced, process automation — from initial setup, process configuration, and Document Image integration, to training your staff how to write custom scripts. We can also create them for you.
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Implementation Services</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        New to Quantum? We can provide full implementation services. From installation and training to reporting and setup. We can provide remote and onsite services to get you live as soon as possible.
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-global-1" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Installation Services</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        We’ll install and configure your hardware and software, helping you achieve optimum stability and performance.
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Performance Evaluation</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        We’ll evaluate your Quantum database performance, then help you bring it back to optimal health. 
                        </div> 
                      </div>
                    </div>
                  </div>

                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Process Flow Management</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        Are you leveraging Quantum to its fullest potential? Are you utilizing best practices in your work flow? Have one of our advanced trainers show you how to improve your processes & save you money.
                        </div> 
                      </div>
                    </div>
                  </div>



                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Quantum Dashboards</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        We’ll develop and install custom widgets giving you real-time awareness to powerfully gauge the health of your business.
                        </div> 
                      </div>
                    </div>
                  </div>


                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Reporting Infrastructure</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        We'll  help you develop a custom reporting infrastructure using Integrated Crystal Reports, Forms Designer, and Event Manager, giving you a clear understanding of your business activity and health.
                        </div> 
                      </div>
                    </div>
                  </div>

                  
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Software Integrator</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        We work with experienced software integrators to make your ERP work best for you. Whether it be a web interface , accounting integration, or any other additions that will help you refine your processes, we have you covered.
                        </div> 
                      </div>
                    </div>
                  </div>

                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Training Services</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        Need training? We'll provide remote & onsite training with beginning & advanced focus. We also provide administrative training for IT staff. Watch out for our webinars &amp; classroom training in your area.
                        </div> 
                      </div>
                    </div>
                  </div>

                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                      <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />{/*  ttm-icon */} 
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title"><h5>Upgrade Support</h5></div>{/* historybox-title  */} 
                        <div className="ttm-historybox-description">{/* description  */} 
                        We’ll help you with your Quantum and database upgrades, ensuring these important events happen quickly and smoothly.
                        </div> 
                      </div>
                    </div>
                  </div>


                  
                </Slider>
                
                <div className="row">
                  <div className="col-md-12 text-center mt-5 res-991-mt-30">
                    
                  </div>
                </div>
              </div>
            </section>
            {/* history-section end*/}
            
            
          </div>
        )
    }
}


export default Home;
